<template>
  <div>
    <apexchart
      type="area"
      :options="chartOptions"
      :series="series"
      :height="height" 
      :width="width"
    />
  </div>
</template>
  
  <script>
  import VueApexCharts from 'vue-apexcharts';
  
  export default {
    name: 'BarChart',
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      height: {
        type: [String, Number],
        default() {
          return 400;
        },
      },
      width: {
        type: [String, Number],
        default() {
          return 500;
        },
      },    
    },
    data() {
      return {
        series: [
          {
            name: 'BarChart',
            data: [14, 20, 47, 30, 25, 40, 30],
          },
        ],
        chartOptions: {
    chart: {
      type: 'bar',
      width: '100%',
      height: '100%',
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
          bar: {
            columnWidth: '80%'
          }
        },
    stroke: {
      curve: 'smooth',
      width: 3,
      colors: ['#5D87FF'],
    },
    colors: ['#5D87FF'], // Main color of the sparkline
    tooltip: {
      fixed: {
        enabled: true,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: () => '',
        },
      },
      marker: {
        show: false,
      },
    },
    yaxis: {
      min: 0, // Adjust this based on your data range to add more space
      max: 50, // Set a maximum range for the Y-axis
    },
    grid: {
      padding: {
        top: 10, // Increase this value to add more space at the top
        bottom: 10, // Optional: Increase to add space at the bottom
        left: 5,
        right: 5,
      },
    },
  },
  
      };
    },
  };
  </script>